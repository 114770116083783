import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { extractMeta, extractBlocks, extractMenus } from "../utils/data"

export const pageQuery = graphql`
  query {
    allBlockContentBasic {
      edges {
        node {
          body {
            processed
          }
          info
        }
      }
    }
    allSite {
      edges {
        node {
          siteMetadata {
            author
            description
            siteUrl
            title
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          menu_name
          enabled
          title
          url
        }
      }
    }
  }
`

const NotFoundPage = (props) => {
  const { meta } = extractMeta(props)
  const { menus } = extractMenus(props)
  const { blocks } = extractBlocks(props)
  return (
    <Layout meta={meta} data={{}} menus={menus} blocks={blocks}>
      <Seo meta={meta} />
      <h2>404: Not Found</h2>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
